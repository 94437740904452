.App {
  text-align: center;
}

.App-logo {
  margin-top: 2vh;
  margin-bottom: 3vh;
  height: 30vh;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.welcome {
  font-size: calc(10px + 2vmin);
}

.whoiam {
  margin-top: -1.2em;
  font-size: calc(10px + 1vmin);
  color: #808080;
}

.whoiam-also {
  margin-top: -0.5em;
  font-size: calc(10px + 1vmin);
  color: #808080;
}

.whoiam code {
  font-size: calc(8px + 1vmin);
}
.whoiam > a, .whoiam-also > a, .contact > a {
  text-decoration: none;
  color: #329be6;
}

.contact {
  margin-top: 4vh;
  font-size: calc(8px + 1.5vmin);
  margin-left: -1.2em;
}

.App-link {
  font-size: calc(10px + 1.5vmin);
  color: #329be6;
  text-decoration: none;
  margin-bottom: 10vh;
}

.iconTelegram {
  position: absolute;
  width: calc(10px + 1.5vmin);
  height: calc(10px + 1.5vmin);
  padding-top: 0.3vmin;
  margin-left: 0.2em;
  /*margin-right: 1em;*/
  transition: all 0.4s ease-in-out;
}

.subHeaderText {
  margin-top: -7vw;
  background-color: #282c34;
  font-size: calc(4px + 1vmin);
  color: #91D5F9;
}

.Jake {
  text-decoration: none;
}

.subText {
  background-color: #282c34;
  font-size: calc(5px + 1vmin);
  color: #808080;
}

.subText > a {
  font-size: calc(5px + 1vmin);
  text-decoration: none;
  color: #91D5F9;
}

#getGems {
  border-radius: 10px;
  border: none;
  width: 98vw;
  height: 70vh;
}

@media only screen and (min-width: 600px) {
  #tgScript {
    margin-left: -3.5vw;
    width: 50vw;
  }
  #getGems {
    width: 38vw;
    height: 70vh;
  }
  .subHeaderText {
    margin-top: -2.2vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
